<template>
  <div class="teacherMaster">
    <a-modal
      v-model="visibleModalManageTeacher"
      class="mod"
      :title="status.isEdit ? 'Edit Teacher' : 'Create Teacher'"
      :destroyOnClose="true"
      @cancel="toggleModalManageTeacher"
      centered
    >
      <a-menu
        v-if="!status.isEdit"
        v-model="currentMenuForm"
        mode="horizontal"
        class="mb-4"
      >
        <a-menu-item key="single">Single</a-menu-item>
        <a-menu-item key="bulk">Bulk</a-menu-item>
      </a-menu>
      <form-teacher
        v-if="currentMenuForm.includes('single')"
        :dataTeacher="newTeacher"
        :listSubjects="listSubjects"
        @handle-change="handleChange"
      />
      <template v-if="currentMenuForm.includes('bulk')">
        <a-form-item label="File Template">
          <div
            class="px-3 py-1 border rounded d-flex justify-content-between align-items-center"
          >
            <div>
              <a-icon class="mr-2" type="file-excel" />
              List Teacher Template.xlsx
            </div>
            <a-tooltip>
              <template slot="title"> Download File </template>
              <a href="/List Teacher Template.xlsx">
                <a-icon style="cursor: pointer" type="download" />
              </a>
            </a-tooltip>
          </div>
        </a-form-item>
        <a-form-item label="Upload Template">
          <a-upload-dragger
            name="fileBulk"
            :multiple="false"
            :before-upload="beforeUploadFileBulk"
            @change="handleChangeUploadBulk"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p class="ant-upload-hint">
              Only support with our template. Please download first <br />
              and put your data to the template
            </p>
          </a-upload-dragger>
        </a-form-item>
      </template>
      <template slot="footer">
        <a-button size="large" key="back" @click="toggleModalManageTeacher">
          Cancel
        </a-button>
        <a-button
          size="large"
          key="submit"
          type="primary"
          :loading="loadingActionModalManageTeacher"
          @click="handleOkModalManageTeacher"
        >
          {{ status.isEdit ? "Edit" : "Create" }} Now
        </a-button>
      </template>
    </a-modal>
    <a-modal
      v-model="visibleModalManagePasswordTeacher"
      class="mod"
      title="Update Password"
      @cancel="toggleModalManagePasswordTeacher"
      centered
    >
      <a-row :gutter="16">
        <a-col :span="24">
          <a-form-item label="New Password">
            <a-input-password
              class="w-100"
              size="large"
              v-model="newPasswordTeacher.password"
            />
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item label="Confirm New Password">
            <a-input-password
              class="w-100"
              size="large"
              v-model="newPasswordTeacher.confirmPassword"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <!-- <form-password-Teacher :dataTeacher="newTeacher" @handle-change="handleChange" /> -->
      <template slot="footer">
        <a-button
          size="large"
          key="back"
          @click="toggleModalManagePasswordTeacher"
        >
          Cancel
        </a-button>
        <a-button
          size="large"
          key="submit"
          type="primary"
          :loading="loadingActionModalManagePasswordTeacher"
          @click="handleOkModalManagePasswordTeacher"
        >
          Update Now
        </a-button>
      </template>
    </a-modal>
    <div class="d-flex align-items center">
      <div>
        <a-input style="min-width: 150px;" v-model="search" @change="fetchDataTeacher" size="large" placeholder="Search by name..."></a-input>
      </div>
      <div class="ml-auto">
        <a-button
          @click.prevent="toggleModalManageTeacher('add')"
          size="large"
          type="primary"
          ><a-icon type="plus" />Create Teacher</a-button
        >
      </div>
    </div>
    <div class="mt-4">
      <a-table
        :columns="teacherColumns"
        :data-source="teacherDataTable"
        :pagination="teacherPagination"
        :loading="teacherLoadingTable"
        bordered
      >
        <template slot="mapel" slot-scope="value, record">
          <template v-if="record.namaMapel && record.namaMapel.length">
            <a-tag class="hsn-tag-overflow-ellipsis" v-for="(mapel, i) in record.namaMapel" :key="i">{{mapel}}</a-tag>
          </template>
          <div v-else>-</div>
        </template>
        <div
          class="d-flex flex-column"
          slot="action"
          slot-scope="value, record"
        >
          <a-button
            @click.prevent="toggleModalManageTeacher('edit', record)"
            class="mb-3 text-warning border border-warning"
            size="default"
          >
            <a-icon type="edit" />Edit
          </a-button>
          <a-button
            @click.prevent="
              toggleModalManagePasswordTeacher('edit-password', record)
            "
            class="mb-3 text-primary border border-primary"
            size="default"
          >
            <a-icon type="key" />Update Password
          </a-button>
          <a-button
            @click.prevent="handleDeleteTeacher(record)"
            class="text-danger border border-danger"
            size="default"
          >
            <a-icon type="delete" />Delete
          </a-button>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
import XLSX from 'xlsx'
const formTeacher = () => import('@/components/app/Admin/Master/Form/Teacher')

const teacherColumns = [
  {
    title: 'NIK',
    dataIndex: 'nik',
    key: 'nik',
    scopedSlots: { customRender: 'nik' },
  },
  {
    title: 'Name',
    dataIndex: 'nama',
    key: 'nama',
    scopedSlots: { customRender: 'nama' },
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    key: 'email',
    scopedSlots: { customRender: 'email' },
    width: 150,
  },
  {
    title: 'Gender',
    dataIndex: 'jenis_kelamin',
    key: 'jenis_kelamin',
    scopedSlots: { customRender: 'jenis_kelamin' },
  },
  {
    title: 'Subject',
    dataIndex: 'namaMapel',
    key: 'namaMapel',
    scopedSlots: { customRender: 'mapel' },
    width: 150,
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
    width: 100,
  },
]
export default {
  name: 'Teacher',
  components: {
    formTeacher,
  },
  data() {
    return {
      teacherColumns,
      visibleModalManageTeacher: false,
      loadingActionModalManageTeacher: false,
      visibleModalManagePasswordTeacher: false,
      loadingActionModalManagePasswordTeacher: false,
      loadingDeleteTeacher: false,
      teacherLoadingTable: false,
      teacherDataTable: [],
      teacherPagination: {},
      status: {
        isEdit: false,
        isCreate: false,
      },
      newTeacher: {
        nik: null,
        nama: null,
        jenis_kelamin: null,
        alamat: null,
        id_mata_pelajaran: [],
        email: null,
      },
      newTeachersBulk: [],
      newPasswordTeacher: {
        password: null,
        confirmPassword: null,
      },
      idEdit: null,
      currentMenuForm: ['single'],
      listSubjects: [],
      search: null,
      order: 'ASC',
      sortBy: 'id',
    }
  },
  methods: {
    handleChange(payload) {
      const { value, column } = payload
      // if (column === 'id_mata_pelajaran') {
      //   this.newTeacher[column].push(value)
      // } else this.newTeacher[column] = value
      this.newTeacher[column] = value
    },
    handleChangeUploadBulk(info) {
      const status = info.file.status
      if (status !== 'uploading') {
        console.log('Change')
      }
      if (status === 'done') {
        // this.$message.success(`${info.file.name} file uploaded successfully.`)
        console.log('Upload Success')
      } else if (status === 'error') {
        console.log('Upload Failed')
        // this.$message.error(`${info.file.name} file upload failed.`)
      }
    },
    beforeUploadFileBulk(file) {
      const acceptFile = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
      if (!acceptFile.includes(file.type)) {
        return this.$notification.error({
          message: 'Warning',
          description: 'Only XLSX file is allowed',
        })
      // } else if (file.name !== 'List Teacher Template.xlsx') {
      //   return this.$notification.error({
      //     message: 'Warning',
      //     description: 'Only with our template file is allowed',
      //   })
      } else {
        const reader = new FileReader()
        reader.onload = () => {
          const fileData = reader.result
          const wb = XLSX.read(fileData, { type: 'binary' })
          console.log(wb.Sheets)
          if (wb.Sheets.TeacherList) {
            const sheets = XLSX.utils.sheet_to_json(wb.Sheets.TeacherList)
            this.newTeachersBulk = sheets
          } else {
            this.$notification.error({
              message: 'Warning',
              description: 'This document is not our template',
            })
          }
        }
        reader.readAsBinaryString(file)
        return false
      }
    },
    toggleModalManageTeacher(status, data) {
      this.visibleModalManageTeacher = !this.visibleModalManageTeacher
      this.currentMenuForm = ['single']
      if (!this.visibleModalManageTeacher) {
        setTimeout(() => {
          this.status = {
            isEdit: false,
            isCreate: false,
          }
          this.newTeacher = {
            nik: null,
            nama: null,
            jenis_kelamin: null,
            alamat: null,
            id_mata_pelajaran: [],
            email: null,
          }
          this.idEdit = null
        }, 500)
        return ''
      }

      if (status) {
        this.status[status === 'add' ? 'isCreate' : 'isEdit'] = true
        if (status === 'edit') {
          this.idEdit = data.key
          this.newTeacher = {
            nik: data.nik,
            nama: data.nama,
            jenis_kelamin: data.jenis_kelamin,
            alamat: data.alamat,
            id_mata_pelajaran: data.id_mata_pelajaran,
            email: data.email,
          }
        }
      }
    },
    handleOkModalManageTeacher() {
      const content = `${this.status.isEdit ? 'Are you sure want to edit this teacher?' : 'Are you sure want to create new teacher'}`
      if (this.isValidateForm) {
        this.$notification.error({
          message: 'Warning',
          description: 'All fields are required',
        })
      } else {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>{content}</div>
          ),
          onOk: () => {
            this.loadingActionModalManageTeacher = true
            const payload = this.status.isEdit ? {
              id: this.idEdit,
              dataTeacher: this.newTeacher,
            } : this.currentMenuForm.includes('bulk') ? {
              dataTeacher: this.newTeachersBulk,
            } : {
              dataTeacher: [this.newTeacher],
            }
            this.$store.dispatch(`master/${this.status.isEdit ? 'EDIT' : 'POST'}_TEACHER`, payload)
              .then(isSuccess => {
                this.loadingActionModalManageTeacher = false
                this.toggleModalManageTeacher()
                this.fetchDataTeacher()
                if (isSuccess) {
                  const description = this.status.isEdit ? 'Teacher has been updated' : 'Teacher has been created'
                  this.$notification.success({
                    message: 'Success',
                    description,
                  })
                } else {
                  const description = this.status.isEdit ? 'Teacher has not been updated' : 'Teacher has not been created'
                  this.$notification.error({
                    message: 'Failed',
                    description,
                  })
                }
              })
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: this.status.isEdit ? 'Edit Now' : 'Create Now',
          cancelText: 'Cancel',
        })
      }
    },
    toggleModalManagePasswordTeacher(status, data) {
      this.visibleModalManagePasswordTeacher = !this.visibleModalManagePasswordTeacher
      if (!this.visibleModalManagePasswordTeacher) {
        setTimeout(() => {
          this.idEdit = null
          this.newPasswordTeacher = {
            password: null,
            confirmPassword: null,
          }
        }, 500)
        return ''
      }

      if (status === 'edit-password') {
        this.idEdit = data.key
      }
    },
    handleOkModalManagePasswordTeacher() {
      if (this.isValidatePasswordForm) {
        this.$notification.error({
          message: 'Warning',
          description: 'All fields are required',
        })
      } else {
        if (this.newPasswordTeacher.password !== this.newPasswordTeacher.confirmPassword) {
          this.$notification.error({
            message: 'Warning',
            description: 'New Password does not match',
          })
        } else {
          this.$confirm({
            title: 'Warning',
            content: (
              <div>Are you sure want update this teacher password?</div>
            ),
            onOk: () => {
              this.loadingActionModalManagePasswordTeacher = true
              const payload = {
                id: this.idEdit,
                password: this.newPasswordTeacher.password,
              }
              this.$store.dispatch('master/UPDATE_PASSWORD_TEACHER', payload)
                .then(isSuccess => {
                  this.loadingActionModalManagePasswordTeacher = false
                  this.toggleModalManagePasswordTeacher()
                  this.fetchDataTeacher()
                  if (isSuccess) {
                    const description = 'Teacher Password has been updated'
                    this.$notification.success({
                      message: 'Success',
                      description,
                    })
                  } else {
                    const description = 'Teacher Password has not been updated'
                    this.$notification.error({
                      message: 'Failed',
                      description,
                    })
                  }
                })
            },
            onCancel: () => {
            },
            centered: true,
            icon: 'warning',
            okType: 'primary',
            okText: 'Update Now',
            cancelText: 'Cancel',
          })
        }
      }
    },
    handleDeleteTeacher(data) {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure want to delete this teacher?</div>
        ),
        onOk: () => {
          this.teacherLoadingTable = true
          this.loadingDeleteTeacher = true
          this.$store.dispatch('master/DELETE_TEACHER', {
            id: data.key,
          })
            .then(isSuccess => {
              this.fetchDataTeacher()
              this.loadingDeleteTeacher = false
              if (isSuccess) {
                this.$notification.success({
                  message: 'Success',
                  description:
                    'Teacher has been deleted',
                })
              } else {
                this.$notification.error({
                  message: 'Failed',
                  description: 'Teacher has not been deleted',
                })
              }
            })
        },
        onCancel: () => {
          this.loadingDeleteTeacher = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Delete',
        cancelText: 'Cancel',
      })
    },
    fetchDataTeacher() {
      this.teacherLoadingTable = true
      this.$store.dispatch('master/FETCH_TEACHER', { order: this.order, search: this.search, sortBy: this.sortBy })
        .then(res => {
          this.teacherLoadingTable = false
          // const pagination = { ...this.pagination }
          // pagination.total = res.total
          // this.pagination = pagination
          // console.log(res)
          this.teacherDataTable = res.map(el => {
            const listMapel = el.slots.map(slot => {
              return slot.mata_pelajaran.nama
            })

            const idMapel = el.slots.map(slot => slot.id_mata_pelajaran)
            return {
              ...el,
              key: el.id,
              namaMapel: listMapel,
              id_mata_pelajaran: idMapel,
            }
          })
        })
    },
    async fetchDataMapel() {
      try {
        const data = await this.$store.dispatch('subject/FETCH_SUBJECTS')
        this.listSubjects = data.data
      } catch (err) {
        console.log(err)
      }
    },
  },
  mounted() {
    this.fetchDataTeacher()
    this.fetchDataMapel()
  },
  computed: {
    isValidateForm() {
      if (this.currentMenuForm.includes('single')) {
        if (this.newTeacher.nik && this.newTeacher.nama && this.newTeacher.jenis_kelamin) {
          return false
        }
      } else if (this.currentMenuForm.includes('bulk')) {
        if (this.newTeachersBulk.length) {
          return false
        }
      }
      return true
    },
    isValidatePasswordForm() {
      if (this.newPasswordTeacher.password && this.newPasswordTeacher.confirmPassword) {
        return false
      }
      return true
    },
  },
}
</script>

<style lang="scss">
.teacherMaster {
  .hsn-tag-overflow-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 150px;
    white-space: nowrap;
  }
}
</style>
